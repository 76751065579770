<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      <ul>
        <li>id</li>
        <li>{{ item["id"] }}</li>
      </ul>
      <ul>
        <li>gameProvider</li>
        <li>{{ item["gameProvider"] }}</li>
      </ul>
      <ul>
        <li>startedAt</li>
        <li>{{ item["startedAt"] }}</li>
      </ul>
      <ul>
        <li>settledAt</li>
        <li>{{ item["settledAt"] }}</li>
      </ul>
      <ul>
        <li>status</li>
        <li>{{ item["status"] }}</li>
      </ul>
      <ul>
        <li>gameType</li>
        <li>{{ item["gameType"] }}</li>
      </ul>
      <ul>
        <li>table id</li>
        <li>{{ item["table"].id }}</li>
      </ul>
      <ul>
        <li>table name</li>
        <li>{{ item["table"].name }}</li>
      </ul>
      <ul>
        <li>dealer uid</li>
        <li>{{ item["dealer"].uid }}</li>
      </ul>
      <ul>
        <li>dealer name</li>
        <li>{{ item["dealer"].name }}</li>
      </ul>
      <ul>
        <li>currency</li>
        <li>{{ item["currency"] }}</li>
      </ul>
      <ul>
        <li>participants</li>
        <li>{{ item["participants"] }}</li>
      </ul>
      <ul>
        <li>result</li>
        <li>{{ item["result"] }}</li>
      </ul>
      <ul>
        <li>wager</li>
        <li>{{ item["wager"] }}</li>
      </ul>
      <ul>
        <li>payout</li>
        <li>{{ item["payout"] }}</li>
      </ul>
      <ul>
        <li>transactionKey</li>
        <li>{{ item["transactionKey"] }}</li>
      </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode216',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
